'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

export default function GlobalError({ error }) {
	useEffect(() => {
		console.warn('*-main-*', error)
		Sentry.captureException(error)
	}, [error])

	return (
		<html lang='en'>
			<body className='flex items-center justify-center min-h-screen bg-gray-100 w-full'>
				<div className='bg-white p-8 rounded-md shadow-sm text-center max-w-lg'>
					<h1 className='text-2xl font-bold text-red-600 mb-4'>An unexpected error occurred</h1>
					<p className='text-lg text-gray-700 mb-4'>
						We are currently experiencing technical difficulties. Our team is actively working on
						resolving the issue. Please try again later.
					</p>
					<a href='/' className='bg-sky-600 text-white px-4 py-2 rounded-md hover:bg-sky-700'>
						Back to Home
					</a>
				</div>
			</body>
		</html>
	)
}
